/*

	**JS init:**

	data = {
		translate: {
			lang: {
				en_full: 'English'
			}
		},
		config: {
			language: 'de'
		}
	}

	var td = new TemplateDynamic({
		template: '#container_id', // container selector
		source_data_link: data, // data source
		live: false // default = true // allow to update template values on the fly
	});

	---------------------------------

	**Html Examples**

	simple text:
	<label>{{translate.task_types.folder}}</label>

	display true/false condition
	<h3 td_if="['Data']['Metadaten']['Bundes-Landesnormen']['Kurztitel']">
		{{['Data']['Metadaten']['Bundes-Landesnormen']['Kurztitel']}}
	</h3>

	// conditional show/hide
	<a href="#" data-lang="en" td_if="config.language != 'en'">{{translate.lang.en_full}}</a>

	// dynamic attribute change
	<input [value]="app.utils.dateFormat(dto.date)">

	// conditional class change
	<div [class.active]="app.activate === true"></div>
*/


var TemplateDynamic = (function(){
	/*var live_variables_watch_list = new Proxy({}, {
			get: function (target, name) {
				return name in target ? Reflect.get(target, name) : target[name] = []; // create empty array if watch_list[prop_name] does not exist
			}
		}),
		old_data_values_list = {},
		data_check_int = 0; // data check setInterval*/

	return function (config) {
		var o = this;

		o.$tpl = config.template.tagName ? config.template : document.querySelector(config.template);
		o.live = config.live !== false;

		o._data_sources = Array.isArray(config.source_data_link) ? config.source_data_link : [config.source_data_link];
		Object.defineProperty(o, "data", {
			get: function () {
				return Object.assign.apply({}, o._data_sources)
			}
		});


		o.$result = null;
		o.a_template_nodes = [];

		o.init = function () {

			/*if (o.live) {
				clearInterval(data_check_int);
				data_check_int = setInterval(o.methods.checkDataChangesAndUpdate, 50);
			}*/

			return o.build();
		};

		o.addDataSource = function (data_source, object_name) {
			if (object_name) {
				var temp_o = {}
				temp_o[object_name] = data_source;
				o._data_sources.push(temp_o)
			} else {
				o._data_sources.push(data_source)
			}
		}


		o.build = function () {
			var nodes_to_remove = [];

			if (!o.$tpl) return false;

			o.$result = document.importNode(o.$tpl.content, true); // create a copy template as document fragment

			o.a_template_nodes = [];

			function collectNodes(node) {
				if (node.childNodes.length) {
					for (var i = 0; i < node.childNodes.length; i++) {
						var n = node.childNodes[i];

						if (n.nodeType === 3 && !o.methods.toUnicode(n.textContent.replace(/\r|\n|\t|\v/g, '')).trim()) {// ignore and remove empty text nodes
							nodes_to_remove.push(n);
							continue;
						}

						o.a_template_nodes.push({node: n, isHidden: false, replacedWith: null, initialValue: n.nodeValue});

						if (n.childNodes.length) collectNodes(n);
					}
				}
			}

			collectNodes(o.$result);

			o.a_template_nodes.forEach(function (el, index) {
				o.methods.handleNode(el, index)
			});

			nodes_to_remove.forEach(function (t) { t.remove() });

			return o.$result;
		};
		o.update = function () {
			o.a_template_nodes.forEach(function (el, index) {
				o.methods.handleNode(el, index)
			});
		};

		o.string_parsers = {
			getStringType: function (str) {
				str = str.trim();

				if (str.match(/\)$/)) return 'function';
				if (str.match(/^'|^"/)) return 'string';
				if (str.match(/^\d+$/)) return 'number';
				if (str.match(/^(?:true|false)$/)) return 'boolean';

				if (str.match(/\S+\s*(?:[!>=<&]{1,3}|[|]{2})\s*\S+/)) {
					return 'condition';
				}

				if (str.match(/(?:[a-zA-Z0-9_'"\[]+(?:\.|]\.|]|$)){2,}/)) {
					return 'path';
				}

				return 'object'
			},
			getTemplateVariableValue: function (str) {
				var eval_str_rgx = /{{\s?([\s\S]+?)\s?}}/g; // /{{\s?(.?[^}\s]+)\s?}}/g;; // var_rgx_str = '{{\\s?([\\s\\S]+?)\\s?}}',

				if (!eval_str_rgx.test(str)) return str;

				eval_str_rgx.lastIndex = 0; // reset rgx index



				return str.replace(eval_str_rgx, function (found_str, prop_path) { // variable replace
					if (o.string_parsers.getStringType(prop_path) === 'condition') {
						return o.string_parsers.getConditionalStringResult(prop_path)
					}
					return o.methods.getObjByStringPath(o.data, prop_path);
				});
			},
			getRawStringValue: function (raw_str, b_for_eval) { // return the value of variable or statement in brackets {{...}}
				let string_type,
					result_str = raw_str;

				raw_str = raw_str.trim();

				string_type = o.string_parsers.getStringType(raw_str);

				if (string_type === 'string') result_str = raw_str.replace(/^["']|["']$/g, ''); // removing unnecessary quotes
				if (string_type === 'number') result_str = Number(raw_str);
				if (string_type === 'boolean') result_str = raw_str === 'true';
				if (string_type === 'object' || string_type === 'path') result_str = o.methods.getObjByStringPath(o.data, raw_str, true); // parsing param value if it present

				if (string_type === 'function') {
					result_str = o.methods.getObjByStringPath(o.data, raw_str, true);
				}

				if (b_for_eval && typeof result_str === 'string') result_str = '\'' + result_str + '\'';

				//dbg('>>>>>>>>>> string_type',string_type, raw_str, result_str)

				return result_str
			},
			getConditionalStringResult: function (conditional_string, el) {
				let rgx_split = /[!>=<&]{1,3}|[|]{2}/g,
					a_split_value_strings,
					exec_result,
					skip = false,
					a_conditions = [],
					a_result = [];

				a_split_value_strings = conditional_string.split(rgx_split);

				//  collecting list of conditions
				while (exec_result = rgx_split.exec(conditional_string)) {
					a_conditions.push(exec_result[0])
				}

				//  collecting list of statements


				a_split_value_strings = a_split_value_strings.map(function (value_str) {
					value_str = value_str.trim();
					return o.string_parsers.getRawStringValue(value_str, true);

				});

				// joining statements and conditions into one array
				a_split_value_strings.forEach(function (item, index, array) {
					if (!skip) {

						if (item === false && (a_conditions[index] === '!' || a_conditions[index] === '!!')) {
							a_result.push(a_conditions[index] === '!' ? !array[index + 1] : !!array[index + 1]);
							skip = true;
						} else {
							a_result.push(item);
							if (a_conditions[index]) a_result.push(a_conditions[index])
						}
					} else {
						skip = false;
						if (typeof array[index + 1] !== 'undefined') a_result.push('&&');
					}

				});

				// evaluate resulting string
				//dbg(2, a_result, conditional_string)
				return eval(a_result.join(''));

			}
		};


		o.methods = {
			toUnicode: function (theString) {
				var unicodeString = '';
				for (var i=0; i < theString.length; i++) {
					var theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
					while (theUnicode.length < 4) {
						theUnicode = '0' + theUnicode;
					}
					theUnicode = '\\u' + theUnicode;
					unicodeString += theUnicode;
				}
				return unicodeString;
			},
			escapeEntities : function (str) {
				str.replace(/[^\x20-\x7E]/g, function(str) {
					return TemplateDynamic_charToEntity[str] ? '&' + TemplateDynamic_charToEntity[str] + ';' : str
				});
			},
			unescapeEntities: function (str) {
				return str.replace(/&(.+?);/g, function(str, ent) {
					return String.fromCharCode( ent[0]!='#' ? TemplateDynamic_entityToCode[ent] : ent[1]=='x' ? parseInt(ent.substr(2),16): parseInt(ent.substr(1)) );
				});
			},
			getObjByStringPath: function (obj, path, b_false_if_no_result) { // right_reduce_num - negative val to reduce result for "num" levels: a.b.c.d >>> num = -2 >>> a.b; b_false_if_no_result - return false instead of path in case if nothing found

				// allowed obj paths: translate.law_search_interface.region_select or jhgjhgjhgj.lkjlkjl1.['fdsf-1'].324324.["fdsf-2"][0]


				if (!path) return false;

				var function_match_rgx = /((?:\w[^)])+)\(\s*((?:[^)])*)\s*\)/,
					split_regex = /(\w+\(\s*((?:[^)])*)\s*\))|\[['"]?([^'()"\]]+)['"]?]|([\w_\d]+)/g,
					exec_step_result,
					a_path_split_result = [],
					result;

				// collecting every path element in array
				while (exec_step_result = split_regex.exec(path)) {
					a_path_split_result.push(exec_step_result.slice(1).filter(function (t) {return t}).join());
				}

				if (typeof obj === 'undefined' || obj === null) {
					if (b_false_if_no_result) return false;
					return path;
				}

				result = a_path_split_result.reduce( function(obj, next_obj_name) {
					var r = null;
					if (typeof next_obj_name === 'string' && next_obj_name.match(function_match_rgx)) {
						var fn_parts = function_match_rgx.exec(next_obj_name);

						r = obj?.[fn_parts[1]].apply(obj, fn_parts[2]?.split(',').map(function (t) { return o.string_parsers.getRawStringValue(t) }) || [])
					} else {
						if (o.string_parsers.getStringType(next_obj_name)  === 'path') {
							next_obj_name = o.methods.getObjByStringPath(o.data, next_obj_name)
						}

						r = obj && typeof obj[next_obj_name] !== 'undefined' ? obj[next_obj_name] : null
					}
					return r;

				}, obj);

				if (typeof result === 'undefined' || result === null) {
					if (b_false_if_no_result) return false;
					return path; // return the input path if no result
				}

				return  result;

			},
			handleNode: function (node_obj, index) {
				var el = node_obj.node,
					type = el.nodeType; // 1 - tag, 3 - text, 8 - comment, 9 - document, 11 - doc fragment

				if (type === 1) {
					if (!node_obj.initial_attributes) node_obj.initial_attributes = Object.assign({}, el.attributes); // copy of initial template attributes for security (remove td_if form dom)

					for (var attr_pair in node_obj.initial_attributes) {
						o.methods.handleAttribute(node_obj.initial_attributes[attr_pair], el, node_obj)
					}
				}
				if (type === 3) {
					var value = o.string_parsers.getTemplateVariableValue(node_obj.initialValue),
						is_html = /<\/?[a-z][\s\S]*>/.test(value);

					while(value !== o.string_parsers.getTemplateVariableValue(value)) { // recursive variable check for cases when the returned text contains variables too
						value = o.string_parsers.getTemplateVariableValue(value)
					}

					if (is_html) {
						//{node: n, isHidden: false, replacedWith: null, initialValue: n.nodeValue}
						var range = document.createRange(); // create an empty list of dom elements (without wrapper)
						node_obj.node.replaceWith(range.createContextualFragment(value)); // createContextualFragment - creates documentFragment with "value"
						range.detach(); // release range to increase performance
					} else {
						el.nodeValue = value;
					}

				}
			},
			toggleNodeVisibility: function (b_state, node_obj) {
				if (b_state) {

					node_obj.isHidden = false;

					if (node_obj.replacedWith) {
						node_obj.replacedWith.replaceWith(node_obj.node);
						node_obj.replacedWith.remove();
						node_obj.replacedWith = null;
					}

				} else {

					node_obj.isHidden = true;

					if (!node_obj.replacedWith) {
						node_obj.replacedWith = document.createComment('removed_tag')
					}

					node_obj.node.replaceWith(node_obj.replacedWith)
				}
			},
			handleDynamicAttribute: function (o_attr_pair, el, node_obj) { //  for attributes like "disabled" "readonly" and so on
				var condition_result = o.string_parsers.getConditionalStringResult(o_attr_pair.value),
					updated_name =  o_attr_pair.name.replace(/^\[|\]$/g, ''),
					updated_name_split_result = updated_name.split('.'); /// for attributes like [class.active]="app.activate === true"

				if (updated_name_split_result[1] && updated_name_split_result[0] === 'class') {

					if (condition_result === true) {
						el.classList.add(updated_name_split_result[1])
					} else {
						el.classList.remove(updated_name_split_result[1])
					}
				} else {
					el[updated_name] = condition_result;

					if (condition_result === true) {
						el.setAttribute(updated_name, condition_result)
					} else {
						el.removeAttribute(updated_name);
					}
				}

				//dbg(el, updated_name, condition_result)
			},
			handleAttribute: function (o_attr_pair, el, node_obj) {
				if (o_attr_pair.name.match(/^\[[\w\.]+\]$/)) { // \. used for [class.active] types
					o.methods.handleDynamicAttribute(o_attr_pair, el, node_obj); //  for attributes like "disabled" "readonly" and so on
				} else if (o_attr_pair.name === 'td_if') {

					node_obj.node.removeAttribute('td_if');
					o.methods.toggleNodeVisibility(o.string_parsers.getConditionalStringResult(o_attr_pair.value), node_obj);
				} else {
					o_attr_pair.value = o.string_parsers.getTemplateVariableValue(o_attr_pair.value);
				}
			}
		};

		return o;

	}
})();

