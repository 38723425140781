function PaginationJs(config) {

	/*

	//init example

	window.onload = function() {
		var pagi = new PaginationJs({
			container_selector: '#pages2',
			items_total: 200,
			items_per_page: 5,
			middle_buttons_count: 3,
			//current_page: 1,
			onChange: function (page_num) {
				console.log('onChange', page_num)
			}
		})

		// update
		pagi.methods.updateConfig({
			current_page: 3,
			items_total: 344
		});


	}*/


	var o = this;

	o.dom = {
		a_page_$els: []
	};

	o.defaults = {
		prev_page_txt: '◄',
		next_page_txt: '►',
		separator_txt: '...'
	};

	o.config = Object.assign({}, o.defaults, config);

	o.current_page = o.config.current_page || 1;

	o.$cont = typeof  o.config.container_selector === 'object' ? o.config.container_selector : document.querySelector(o.config.container_selector);;

	o.init = function() {
		o.render();
		//o.setupEvents();

		o.actions.setPage(o.current_page); // activate current page form config

	};
	o.reset = function () {
		o.$cont.innerHTML = '';
		o.dom.a_page_$els = [];
	};

	o.methods = {
		updateConfig: function (conf) {
			o.config = Object.assign(o.config, conf);
			if (conf.current_page) o.current_page = parseInt(conf.current_page);
			if (o.methods.getPagesCount() < o.current_page) o.current_page = 1; // check impossible values

			o.$cont.classList.toggle('pagination_js_hidden', o.methods.getPagesCount() === 1)

			o.init();
		},
		getPagesCount: function () {
			return Math.ceil(o.config.items_total / o.config.items_per_page)
		},
		b_shouldBeVisible: function (el_page_num) {

			var cp = o.current_page,
				mbc = o.config.middle_buttons_count,
				max_visible = mbc * 2 + 2, // one btn in the middle + "mbc" offset from its both sides + 1 additional offset to compensate separators appearance
				pages_total = o.methods.getPagesCount();

			if (el_page_num === 1  || el_page_num === pages_total) return true;

			if (el_page_num <= max_visible+1 && cp - mbc <= el_page_num) { // left buttons conditions
				//dbg('1 left', el_page_num)
				return true;
			}

			if (el_page_num <= cp + mbc && el_page_num >= cp - mbc)  { // middle buttons conditions
				//dbg('2 middle', el_page_num)
				return true;
			}

			if (el_page_num >= pages_total - max_visible && cp >= el_page_num) { // right buttons conditions
				return true;
			}

			//return false;
		},
		hasOnlyOnePage: function () {
			return o.methods.getPagesCount() === 1;
		},
		isCurrentPageNotLastPage: function () {
			return o.methods.getPagesCount() > 1 && o.current_page < o.methods.getPagesCount()
		}
	};


	o.view = {
		$getPageEl: function (text, page_number) {
			var $el = document.createElement('a');

			$el.setAttribute('href', 'javascript:void(0);');

			$el.innerHTML = text;

			//$el.addEventListener('click', click_fn);
			if (page_number) {
				$el.setAttribute('page_number', page_number);
				o.dom.a_page_$els.push($el);
			}


			return $el;
		}
	};

	o.render = function () {

		var visible_btns_count = 0;

		o.reset();

		if (o.methods.getPagesCount()) {

			o.$cont.style.display = '';

			o.dom.$left_separator = document.createElement('span');
			o.dom.$left_separator.innerText = o.config.separator_txt;
			o.dom.$right_separator = o.dom.$left_separator.cloneNode(true);

			o.dom.$left_separator.innerText = o.config.separator_txt;

			o.dom.$prev_page_btn = o.view.$getPageEl(o.config.prev_page_txt);
			o.dom.$next_page_btn = o.view.$getPageEl(o.config.next_page_txt);

			o.$cont.appendChild(o.dom.$prev_page_btn);


			for (var i = 0, len = o.methods.getPagesCount(); i < len; i++) {

				var el_page_num = i + 1;

				if (i === 1) o.$cont.appendChild(o.dom.$left_separator);


				if (o.methods.b_shouldBeVisible(el_page_num)) {

					visible_btns_count++;

					var $el = o.view.$getPageEl(i + 1, i + 1);
					o.$cont.appendChild($el);

					if (el_page_num === o.current_page) $el.classList.add('active');

				}

				if (i === len - 2) o.$cont.appendChild(o.dom.$right_separator);
			}

			o.$cont.appendChild(o.dom.$next_page_btn);

			if (o.current_page === 1) o.dom.$prev_page_btn.classList.add('disabled');
			if (o.current_page === o.methods.getPagesCount()) o.dom.$next_page_btn.classList.add('disabled');

			if (o.current_page <= o.config.middle_buttons_count + 2 || visible_btns_count === o.methods.getPagesCount()) o.dom.$left_separator.classList.add('hidden');
			if (o.current_page + o.config.middle_buttons_count >= o.methods.getPagesCount() - 1 || visible_btns_count === o.methods.getPagesCount()) o.dom.$right_separator.classList.add('hidden');

			o.setupEvents();
		} else {
			o.$cont.style.display = 'none';
		}

	};



	o.update = function () {

		if (o.dom.a_page_$els.length) {

			o.$cont.style.display = '';

			for (var i = 0, len = o.dom.a_page_$els.length; i < len; i++) {
				var $p_el = o.dom.a_page_$els[i],
					el_page_num = i + 1;

				$p_el.classList.remove('active', 'hidden');
				$p_el.classList.add('hidden');

				if (o.methods.b_shouldBeVisible(el_page_num)) $p_el.classList.remove('hidden');

				//if (i+1 > o.current_page - o.config.middle_buttons_count && i+1 < o.current_page + o.config.middle_buttons_count) $p_el.classList.remove('hidden');
			}

			o.dom.a_page_$els[o.current_page-1].classList.add('active');

		} else {
			o.$cont.style.display = 'none';
		}



		o.dom.$prev_page_btn.classList.remove('disabled');
		o.dom.$next_page_btn.classList.remove('disabled');

		if (o.current_page === 1) o.dom.$prev_page_btn.classList.add('disabled');
		if (o.current_page === o.methods.getPagesCount()) o.dom.$next_page_btn.classList.add('disabled');


		o.dom.$left_separator.classList.remove('hidden');
		o.dom.$right_separator.classList.remove('hidden');

		if (o.current_page <= o.config.middle_buttons_count + 2  ) o.dom.$left_separator.classList.add('hidden');
		if (o.current_page + o.config.middle_buttons_count >= o.methods.getPagesCount() - 1) o.dom.$right_separator.classList.add('hidden');

	};

	o.setupEvents = function () {
		o.dom.$prev_page_btn.addEventListener('click', o.actions.prevPage);
		o.dom.$next_page_btn.addEventListener('click', o.actions.nextPage);

		for (var i = 0, len = o.dom.a_page_$els.length; i < len; i++) {
			var $p_el = o.dom.a_page_$els[i];

			$p_el.addEventListener('click', o.actions.switchPage);
		}

	};

	o.actions = {
		setPage: function (n) {
			o.current_page = n;
			o.render();
		},
		switchPage: function (e) {
			o.current_page = e ? parseInt(this.getAttribute('page_number')) : o.current_page;
			o.render();

			if (o.config.onChange) o.config.onChange(o.current_page);
			//console.log(o.current_page);
		},
		prevPage: function (e) {
			if (o.current_page - 1 > 0) {
				o.current_page -= 1;
				o.actions.switchPage()
			}
		},
		nextPage: function (e) {
			if (o.current_page + 1 <= o.methods.getPagesCount()) {
				o.current_page += 1;
				o.actions.switchPage()
			}
		},
	};


	o.init();
};
