/*

	<ul class="tab_switcher_nav">
		<li data-tab="tab1">Tab-1</li>
		<li data-tab="tab2">Tab-2</li>
		<li data-tab="tab3">Tab-3</li>
		<li data-tab="tab1">Tab-4-1</li>
	</ul>
	<div class="tab_switcher_contents">
		<div data-tab-content="tab1">t1</div>
		<div data-tab-content="tab2">t2</div>
		<div data-tab-content="tab3">t3</div>
	</div>

	<script src="tab_switcher.js"></script>
	<script>
		var $ell = document.querySelector('.tab_switcher_nav');
		tab_switcher($ell);

		$ell.addEventListener('show.tab_switcher', function (e) {console.log(e, this)})
		$ell.addEventListener('shown.tab_switcher', function (e) {console.log(e, this)})
		$ell.addEventListener('hide.tab_switcher', function (e) {console.log(e, this)})
		$ell.addEventListener('hidden.tab_switcher', function (e) {console.log(e, this)})

	</script>

 */

function TabSwitcher($nav_el, config) {

	var ts = this;

	ts.active_tab = null;

	ts.content_wrapper = $nav_el.nextElementSibling;

	Object.defineProperty(ts, "$tabs", {
		get: function () {
			return $nav_el.children;
		}
	});

	Object.defineProperty(ts, "$content_conts", {
		get: function () {
			return ts.content_wrapper.children;
		}
	});



	var init = function () {

		/*for (var i = 0, $tab;  $tab = ts.$tabs[i]; i++) {
			$tab.addEventListener('click', ts.switchTab)
		}*/

		$nav_el.addEventListener('click', ts.switchTab);


		if (![].filter.call(ts.$tabs, function ($t) {return $t.classList.contains('active')}).length) {

			ts.$tabs[0].click();
		} else {
			var $el = [].find.call(ts.$tabs, function(el){
				return el.classList.contains('active');
			});

			if ($el) $el.click();
		}

		//if (config && config.equal_heights) setEqualHeights();
	};

	ts.destroy = function () {
		for (var i = 0, $tab;  $tab = ts.$tabs[i]; i++) {
			$tab.removeEventListener('click', ts.switchTab)
		}
		return this
	};
	ts.switchTab = function (e) {
		var $tab = e.target;

		if (e.target === $nav_el) return false;

		while ($tab !== $nav_el && $tab.getAttribute('data-tab') === null) {
			$tab = $tab.parentNode
		}

		var $prev_active_tab = $nav_el.querySelector('.active'),
			prev_active_tab_content_id,
			$prev_active_tab_cont,
			prev_tab_details,
			tab_content_id = $tab.getAttribute('data-tab'),
			$tab_content_cont = ts.content_wrapper.querySelector('[data-tab-content=' + tab_content_id + ']'),
			tab_details = {bubbles: true, detail: {tab: $tab, content_cont: $tab_content_cont}};


		if ($prev_active_tab) {
			prev_active_tab_content_id = $prev_active_tab.getAttribute('data-tab');
			$prev_active_tab_cont = ts.content_wrapper.querySelector('[data-tab-content=' + prev_active_tab_content_id + ']');
			prev_tab_details = {bubbles: true, detail: {tab: $prev_active_tab, content_cont: $prev_active_tab_cont}};

			$nav_el.dispatchEvent(new CustomEvent('hide.tab_switcher', prev_tab_details));
		}

		$nav_el.dispatchEvent(new CustomEvent('show.tab_switcher', tab_details));

		[].forEach.call(ts.$tabs, function($el) {$el.classList.remove('active')});
		[].forEach.call(ts.$content_conts, function($el) {$el.classList.remove('active')});

		if ($prev_active_tab) {
			$nav_el.dispatchEvent(new CustomEvent('hidden.tab_switcher', prev_tab_details));
		}


		$tab.classList.add('active');
		$tab_content_cont.classList.add('active');

		$nav_el.dispatchEvent(new CustomEvent('shown.tab_switcher', tab_details));
		//$nav_el.dispatchEvent(new CustomEvent('click', tab_details));

		ts.active_tab = $tab;

	};

	ts.$getTabContentContainerById = function (id) {
		return ts.content_wrapper.querySelector('[data-tab-content=' + id + ']');
	};


	ts.switchTabById = function(id) {
		var $tab = [].find.call(ts.$tabs, function($el) {return $el.getAttribute('data-tab') && $el.getAttribute('data-tab') === id});

		if ($tab) $tab.click();

	};


	/*var setEqualHeights = function () {
		var temp_h = 0,
			max_height = 0;

		[].forEach.call(ts.$content_conts, function ($t) {
			$t.style.height = null;
			dbg($t, $t.offsetHeight)
			if ($t.offsetHeight > temp_h) temp_h = $t.offsetHeight;
		});
		max_height = temp_h;

		dbg(max_height);
		[].forEach.call(ts.$content_conts, function ($t) {
			$t.style.height = max_height + 'px';
		})

	};*/

	init();

	return ts;

}
