function ScrollbarsJs($el, custom_config) { // custom_config (optional)

	var o = this;

	var is_mobile = 'ontouchstart' in document.documentElement;
	if (!$el) return;

	if (is_mobile) {
		$el.classList.add('scrollbars', 'scrollbars_mobile');
		return;
	}


	var passiveSupported = false;
	try {
		window.addEventListener('test',null, Object.defineProperty({}, "passive", { get: function() { passiveSupported = true; } }));
	} catch(err) {}
	//$el.classList.add('scrollbars_active'); // add overflow: hidden !important

	var d = document,
		math = {
			easeInOutQuad: function (t, b, c, d) {
				t /= d / 2;
				if (t < 1) {
					return c / 2 * t * t + b
				}
				t--;
				return -c / 2 * (t * (t - 2) - 1) + b;
			},
			easeInCubic: function (t, b, c, d) {
				var tc = (t /= d) * t * t;
				return b + c * (tc);
			},
			inOutQuintic: function (t, b, c, d) {
				var ts = (t /= d) * t,
					tc = ts * t;
				return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
			},
			easeInQuad: function (t, b, c, d) {
				t /= d;
				return c * t * t + b;
			},
			easeOutQuad: function (t, b, c, d) {
				t /= d;
				return -c * t * (t - 2) + b;
			},
			easeOutSine: function (t, b, c, d) {
				return c * Math.sin(t/d * (Math.PI/2)) + b;
			}
		};

	o.config = {
		scroll_inc: 12,
		ani_fade_inc: 20,
		ani_fade_duration: 100,
		active_class: 'scrollbars_active',
		container_class: 'scrollbars',
		bar_class: 'scrollbars_bar',
		ease_fn: 'easeOutQuad',
		use_keys: true
	};

	Object.assign(o.config, custom_config ? custom_config : {});

	var main_values_changed_ev = new Event("main_values_changed");

	var visible_height, content_height, visible_width, content_width, max_scroll_top,
		max_scroll_left, $scroll_bar_y, $scroll_bar_x,
		content_top_offset_in_percent, content_left_offset_in_percent;

	var refresh_int = 0, scroll_tmt = 0, resize_tmt = 0, destroy_countdown = 5, main_values_changed = true,
		wheel_scroll_tmt = 0,
		wheel_scroll = false,
		drag_scroll = false;

	var a_registered_events = [];

	var on = function (eventType, fn, $evObj) {
		$evObj.addEventListener(eventType, fn, {passive: true});
		a_registered_events.push({type: eventType, $evObj: $evObj, fn: fn})
	};

	var off = function (eventType, fn, $evObj, b_do_not_update_arr) {

		$evObj.removeEventListener(eventType, fn);

		if (!b_do_not_update_arr) {
			a_registered_events = a_registered_events.filter(function(item){
				return !(item.type === eventType && item.$evObj === $evObj && item.fn === fn);
			});
		}
	};

	var destroyAllRegisteredEvents = function () {
		for (var i = 0; i < a_registered_events.length; i++) {
			var eData = a_registered_events[i];
			off(eData.type, eData.fn, eData.$evObj, true)
		}
		a_registered_events = [];
	};

	var init = function () {

		var el_style_position = window.getComputedStyle($el).position;

		if (!el_style_position.match(/absolute|relative|fixed/)) {
			$el.style.position = 'relative';
		}

		$el.classList.add(o.config.active_class);
		$el.classList.add(o.config.container_class);
		addBars();
		setupEvents();

		refresh_int = setInterval(refresh, 2000); // periodical update to avoid interface distortions when content size changes

		//document.removeEventListener("selectionchange", event_functions.selectionchange);
		//document.addEventListener("selectionchange", event_functions.selectionchange);

		window.dispatchEvent(new Event('resize'));
	};

	var getCoords = function (elem) {
		var box = elem.getBoundingClientRect();
		return {top: box.top + pageYOffset, left: box.left + pageXOffset};
	};

	var isElementInView = function ($obj) {
		var el_box = getCoords($el),
			obj_box = getCoords($obj);

		if (obj_box.top - el_box.top < 0) return false
		if (obj_box.top - el_box.top > $el.offsetHeight) return false

		if (obj_box.left - el_box.left < 0) return false
		if (obj_box.left - el_box.left > $el.offsetWidth) return false

		return true;
	}


	o.destroy = function () {
		clearTimeout(scroll_tmt);
		clearTimeout(resize_tmt);
		clearInterval(refresh_int);
		$scroll_bar_x.remove();
		$scroll_bar_y.remove();
		$el.classList.remove(o.config.active_class);
		$el.classList.remove(o.config.container_class);

		//document.removeEventListener("selectionchange", event_functions.selectionchange);
		destroyAllRegisteredEvents();
	};

	var refresh = function () {

		if (!document.body.contains($el)) { // autodestroy if element was removed
			--destroy_countdown;
			if (destroy_countdown === 0) o.destroy();
		} // $el was removed

		updateMainValues();

	};

	var updateMainValues = function () {

		var el_style = getComputedStyle($el),
			border_left = el_style.borderLeftWidth ? parseInt(el_style.borderLeftWidth) : 0,
			border_right = el_style.borderRightWidth ? parseInt(el_style.borderRightWidth) : 0,
			border_top = el_style.borderTopWidth ? parseInt(el_style.borderTopWidth) : 0,
			border_bottom = el_style.borderBottomWidth ? parseInt(el_style.borderBottomWidth) : 0;

		var initial_visible_height = visible_height,
			initial_content_height = content_height,
			initial_visible_width = visible_width,
			initial_content_width = content_width;

		$scroll_bar_x.style.display = 'none'; // hiding scrollbars to get right values of content dimensions
		$scroll_bar_y.style.display = 'none';

		visible_height = $el.offsetHeight - border_top - border_bottom;
		content_height = $el.scrollHeight;
		visible_width = $el.offsetWidth - border_left - border_right;
		content_width = $el.scrollWidth;
		max_scroll_top = content_height - visible_height;
		max_scroll_left = content_width - visible_width;

		$scroll_bar_x.style.display = 'block';
		$scroll_bar_y.style.display = 'block';



		main_values_changed =
			initial_visible_height !== visible_height ||
			initial_visible_width !== visible_width ||
			initial_content_height !== content_height ||
			initial_content_width !== content_width;

		if (main_values_changed) {
			$el.dispatchEvent(main_values_changed_ev);
		}


	};

	var addBars = function () {
		$scroll_bar_x = d.createElement('scroller');
		$scroll_bar_y = d.createElement('scroller');
		$scroll_bar_x.classList.add(o.config.bar_class, o.config.bar_class + '_x');
		$scroll_bar_y.classList.add(o.config.bar_class, o.config.bar_class + '_y');
		$el.appendChild($scroll_bar_y);
		$el.appendChild($scroll_bar_x);
	};

	var mouseDownActions = function (e) {
		drag_scroll = true;
		$el.classList.add('scrolling');

		on('selectstart', event_functions.disableSelect, $el); // prevent text selection while dragging slider

		updateMainValues();
		e.stopPropagation();
	};

	var mouseUpActions = function (e) {
		drag_scroll = false;
		$el.classList.remove('scrolling');

		off('selectstart', event_functions.disableSelect, $el); // prevent text selection while dragging slider
	};


	var _selection_change_tmt = 0;
	var event_functions = {
		keydown: function (e) {

			if (e.target.tagName.match(/TEXTAREA|INPUT/)) return false;

			if ($el.focused) {

				if (e.keyCode === 37) o.setScrolls(null, -o.config.scroll_inc, true); // arrow LEFT
				if (e.keyCode === 39) o.setScrolls(null, o.config.scroll_inc, true); // arrow RIGHT

				if (e.keyCode === 38) o.setScrolls(-o.config.scroll_inc, null, true); // arrow UP
				if (e.keyCode === 40) o.setScrolls(o.config.scroll_inc, null, true); // arrow DOWN

				if (e.keyCode === 33) o.setScrolls(-$scroll_bar_y.offsetHeight, null, true); // PAGE UP
				if (e.keyCode === 34) o.setScrolls($scroll_bar_y.offsetHeight, null, true); // PAGE DOWN

				if (e.keyCode === 36) o.setScrolls(-100000, null, true); // HOME
				if (e.keyCode === 35) o.setScrolls(100000, null, true); // END
			}
		},
		mouseenter: function (e) {
			this.focused = true;
		},
		updateBars: function () {
			if (visible_height > 0) {
				$scroll_bar_y.style.height = visible_height * 100 / content_height + '%';
				$scroll_bar_y.style.visibility = (content_height <= visible_height) ? 'hidden' : 'visible';
				if (content_height <= visible_height) $scroll_bar_y.style.top = 0;

				$scroll_bar_x.style.width = visible_width * 100 / content_width + '%';
				$scroll_bar_x.style.visibility = (content_width <= visible_width) ? 'hidden' : 'visible';
				if (content_width <= visible_width) $scroll_bar_x.style.left = 0;

				o.setScrolls(0, 0);
			}
		},
		mouseleave: function (e) {
			this.focused = false;
			//document.removeEventListener("selectionchange", event_functions.selectionchange);
		},
		wheel: function (e) {

			if (this.classList.contains('scrollbars_disabled')) return false;

			wheel_scroll = true;
			var sign = e.deltaY > 0 ? 1 : -1,
				move_value = sign * o.config.scroll_inc;




			if (move_value + $el.scrollTop > max_scroll_top) {
				move_value = max_scroll_top - $el.scrollTop;
			}

			o.setScrolls(move_value, null, true);

			clearTimeout(wheel_scroll_tmt);
			wheel_scroll_tmt = setTimeout(function () { wheel_scroll = false }, o.config.ani_fade_duration + 100);

			//e.preventDefault();
			//dbg(sign, content_top_offset_in_percent, content_height > visible_height)

			if (content_height > visible_height) {
				if ((sign === -1 && content_top_offset_in_percent !== 0) || (sign === 1 && content_top_offset_in_percent !== 100)) {
					e.stopPropagation();
				}
			}


		},
		scroll: function (e) {
			// implementation of manual $el.scrollLeft / $el.scrollTop assigment
			if (!wheel_scroll && !drag_scroll) {
				o.setScrolls(0,0);
			}
		},
		mousedown_x: function (e) {

			mouseDownActions(e);

			var elCoords = getCoords($scroll_bar_x);
			var shiftX = e.pageX - elCoords.left;

			var parentCoords = getCoords($el),
				requestAnimationFrameTmt = 0;

			var mouseMoveX = function (e) {

				if (requestAnimationFrameTmt) window.cancelAnimationFrame(requestAnimationFrameTmt);

				requestAnimationFrameTmt = window.requestAnimationFrame(function () {
					var newLeft = e.pageX - shiftX - parentCoords.left; //  вычесть координату родителя, т.к. position: relative

					if (newLeft <= 0) newLeft = 0; // курсор ушёл за пределы

					var rightEdge = visible_width - $scroll_bar_x.offsetWidth;

					if (newLeft >= rightEdge) newLeft = rightEdge;

					var bar_new_left = newLeft + $el.scrollLeft,
						content_left_offset_ratio = $el.scrollLeft / max_scroll_left;

					$scroll_bar_x.style.left = bar_new_left + 'px';
					if(max_scroll_top) $scroll_bar_y.style.right = -$el.scrollLeft + 'px';

					$el.scrollLeft = bar_new_left - content_left_offset_ratio * (visible_width - $scroll_bar_x.offsetWidth);
				});

			};
			var mouseUpX = function () {
				off('mousemove', mouseMoveX, d);
				off('mouseup', mouseUpX, d);

				mouseUpActions();
			};

			on('mousemove', mouseMoveX, d);
			on('mouseup', mouseUpX, d);

			return false; // disable selection start (cursor change)
		},
		dragstart: function () {return false},
		mousedown_y: function (e) {

			mouseDownActions(e);

			var elCoords = getCoords($scroll_bar_y);
			var shiftY = e.pageY - elCoords.top;

			var parentCoords = getCoords($el),
				requestAnimationFrameTmt = 0;

			var mouseMoveY = function (e) {

				if (requestAnimationFrameTmt) window.cancelAnimationFrame(requestAnimationFrameTmt);

				// Setup the new requestAnimationFrame()
				requestAnimationFrameTmt = window.requestAnimationFrame(function () {

					var newTop = e.pageY - shiftY - parentCoords.top; //  вычесть координату родителя, т.к. position: relative

					if (newTop < 0) newTop = 0; // курсор ушёл за пределы

					var bottomEdge = visible_height - $scroll_bar_y.offsetHeight;

					if (newTop >= bottomEdge) newTop = bottomEdge;

					var bar_new_top = newTop + $el.scrollTop,
						content_top_offset_ratio = $el.scrollTop / max_scroll_top;

					$scroll_bar_y.style.top = bar_new_top + 'px';
					$el.scrollTop = bar_new_top - content_top_offset_ratio * (visible_height - $scroll_bar_y.offsetHeight);

					if (max_scroll_left) $scroll_bar_x.style.bottom = -$el.scrollTop + 'px';


				});

			};
			var mouseUpY = function () {
				off('mousemove', mouseMoveY, d);
				off('mouseup', mouseUpY, d);

				mouseUpActions();
			};
			on('mousemove', mouseMoveY, d);
			on('mouseup', mouseUpY, d);

			return false; // disable selection start (cursor change)
		},
		resize: function() {
			clearTimeout(resize_tmt);
			resize_tmt = setTimeout(refresh, 50);
		},
		disableSelect: function(event) {
			event.preventDefault();
		},
		selectstart: function (event) {
			//document.addEventListener("selectionchange", event_functions.selectionchange);
		},
		selectionchange: function (event) {
			var selection = window.getSelection(),
				$selectedParent = selection && selection.anchorNode ? selection.anchorNode.parentNode : null;


			if (selection && selection.type === 'Range') {

				if ($el.contains($selectedParent)) {
					if (!isElementInView($selectedParent)) {
						$selectedParent.scrollIntoView()
					}
				}

				//
			}
		}
	};

	var setupEvents = function () {

		on('resize', event_functions.resize, window);

		if (o.config.use_keys) {
			on('keydown', event_functions.keydown, window);
		}

		on('main_values_changed', event_functions.updateBars, $el);

		on('mouseenter', event_functions.mouseenter, $el);
		on('mouseleave', event_functions.mouseleave, $el);
		on('wheel', event_functions.wheel, $el, passiveSupported ? { passive: true } : false);
		on('scroll', event_functions.scroll, $el, passiveSupported ? { passive: true } : false);

		on('mousedown', event_functions.mousedown_x, $scroll_bar_x);
		on('dragstart', event_functions.dragstart, $scroll_bar_x);

		on('mousedown', event_functions.mousedown_y, $scroll_bar_y);
		on('dragstart', event_functions.dragstart, $scroll_bar_y);


		on('selectstart', event_functions.selectstart, $el)

	};

	o.setScrolls = function(inc_val_y, inc_val_x, b_animate) {
		var current_time = 0;

		updateMainValues();

		if (inc_val_y && $el.scrollTop + inc_val_y > max_scroll_top) {
			inc_val_y = max_scroll_top - $el.scrollTop;
		}
		if (inc_val_x && $el.scrollLeft + inc_val_x > max_scroll_top) {
			inc_val_x = max_scroll_left - $el.scrollLeft;
		}

		function setPositions(scrollTop, scrollLeft) {

			// scroll top updates -------------------
			$el.scrollTop = scrollTop; // find the value with the quadratic in-out easing function

			content_top_offset_in_percent = $el.scrollTop * 100 / max_scroll_top;

			var 	bar_top_val = $el.scrollTop + content_top_offset_in_percent * (visible_height - $scroll_bar_y.offsetHeight) / 100;

			$scroll_bar_y.style.top = bar_top_val + 'px';
			if (content_top_offset_in_percent === 100) $el.scrollTop = max_scroll_top;

			$scroll_bar_y.style.right = -$el.scrollLeft + 'px'; // hack to maintain right position


			// scroll left updates -------------------
			$el.scrollLeft = scrollLeft;

			content_left_offset_in_percent = $el.scrollLeft * 100 / max_scroll_left;

			var bar_left_val = $el.scrollLeft + content_left_offset_in_percent * (visible_width - $scroll_bar_x.offsetWidth) / 100;

			$scroll_bar_x.style.left = bar_left_val + 'px';
			if (content_left_offset_in_percent === 100) $el.scrollLeft = max_scroll_left;


			$scroll_bar_x.style.bottom = -$el.scrollTop + 'px'; // hack to maintain right position

		}


		function animateScroll() {

			current_time += o.config.ani_fade_inc; // increment the time

			if (inc_val_y !== undefined) {
				var scrollTop = math[o.config.ease_fn](current_time, $el.scrollTop, inc_val_y, o.config.ani_fade_duration); // find the value with the quadratic in-out easing function
			}
			if (inc_val_x !== undefined) {
				var scrollLeft = math[o.config.ease_fn](current_time, $el.scrollLeft, inc_val_x, o.config.ani_fade_duration);
			}

			setPositions(scrollTop, scrollLeft);

			if (current_time <= o.config.ani_fade_duration) {// do the animation unless its over
				window.requestAnimationFrame(animateScroll);
			}
		}

		if (b_animate) {
			animateScroll();
		} else {
			var	scrollTop = $el.scrollTop + inc_val_y * o.config.ani_fade_duration/o.config.ani_fade_inc,
				scrollLeft = $el.scrollLeft + inc_val_x * o.config.ani_fade_duration/o.config.ani_fade_inc;
			setPositions(scrollTop, scrollLeft)
		}

	};

	init();

	return o;
}